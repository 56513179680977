
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import DatePicker from '@/components/DatePicker.vue';
import TaxDeclarationService from '@/services/TaxDeclarationService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import {required} from '@/utils/validationRules';

@Component({
  name: 'DisableDeclarationDialog',
  components: {DatePicker},
})
export default class DisableDeclarationDialog extends Vue {
  dateTo: string = '';
  loading: boolean = false;
  rules = {
    dateTo: [required],
  };

  @Prop()
  declarationId!: number | null;

  @Watch('declarationId')
  onDeclarationIdChanged(val: number | null) {
    if (!val) {
      this.dateTo = '';
      // @ts-ignore
      this.$refs.form.resetValidation();
    }
  }

  async save() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        await TaxDeclarationService.disable(this.declarationId as number, this.dateTo);
        notify(
            NotificationTypes.success,
            this.$t('SYSTEM_ENDED'),
            this.$t('SYSTEM_TAX_DEC_ENDED'),
        );
        this.$emit('disabled');
      } catch (e) {
        resolveError(e, 'disable');
      } finally {
        this.loading = false;
      }
    }
  }

  @Emit('close')
  close() {
    return;
  }
}
